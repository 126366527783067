@import "lib/_flatpickr";
@import "lib/_nprogress";
@import "lib/_swiper";

/** Outgrow Chat Styles **/
@import "https://dyv6f9ner1ir9.cloudfront.net/assets/css/shared/chatV2.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

/* Commented out - Causes bug with mobile menu
:root {
  overflow-x: hidden;
}*/

body {
  overflow: hidden auto;
}

blockquote {
  color: #924E8C;
  border-left: 3px solid #EEEEF9;
  padding-left: 24px;
}

.gray-bg {
  background-color: #f3f3f3 !important;
}

.chakra-input.st-default-search-input {
  border-radius: 0;
  border: none;
  height: 24px;
  line-height: 24px;
  font-family: "TWK Everett";
  background-position: 8px 12px;
}

.st-injected-content-generated .st-ui-injected-overlay-container {
  border-radius: 0;
  border: 0;
  font-family: "TWK Everett", sans-serif;
}

.st-injected-content-generated .st-ui-injected-overlay-container *:not(select),
.st-injected-content-generated
.st-ui-injected-overlay-container
.st-ui-header
input[type="text"],
.st-ui-injected-overlay-container .st-ui-content * {
  font-family: "TWK Everett", sans-serif;
}

.st-default-autocomplete div.st-ui-autocomplete div.st-query-present,
section.st-ui-slide-autocomplete div.st-query-present {
  font-family: "TWK Everett", sans-serif;
  border-radius: 0;
  border: 0;
}

.st-injected-content-generated
.st-ui-injected-overlay-container
.st-ui-header
input[type="text"]:focus {
  border-bottom: 2px solid #6863f2;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
  display: flex;
  align-items:center;
}

/* Team Page */

.TeamLayout__header .PageTitle h1 {
  font-size:40px;
  line-height:50px;
  margin-bottom:16px;
}

.TeamLayout__header .PageTitle__summary-text {
  font-size:20px;
  line-height:28px;
}

/* Resource Center */

.resourceHeader__wrapper {
  background-color: rgb(238 238 249 / var(--tw-bg-opacity));
}

.resourceHeader__wrapper--dark {
  background-color: var(--primary-purple-purple-02, #483698);
}

.resourceHeader__title {
  color: #6863F2;
}
.resourceHeader__wrapper--dark .resourceHeader__title {
  color: #B2F5E2;
}
.flex-col-reverse {
  column-gap: 43px;
}

@media screen and (max-width: 768px) {
  .ColumnLayout__sidebar {
    padding-top: 25px;
  }
}

/* ISC2 Credit Box */

.isc2CreditBox__Wrapper {
  margin: 1.25rem auto;
  background-color: #F6F6F9
}

@media screen and (max-width: 768px) { 
  .resourceHeader__flexContainer {
    display: block;
  }
  
  .resourceHeader__column--two-thirds {
    max-width: 100%;
    margin-left: 0px !important;
  }
  
  .resourceHeader__column--one-third {
    max-width: 100%;
  }
}

/* Styles from Embedded CSS In Plasmic Projects */
.anchor {
  display: block;
  position: relative;
  top: 0px;
  visibility: hidden;
}

.transition-2 {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
  opacity: 1;
}

/* Cuts the top left and top right corners of a box at a 45degree angle - add to any plasmic element */
.cut-corners-top {
  clip-path: polygon(20px 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%, 0 20px);
}
